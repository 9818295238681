import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { commonRouting, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyAccountService } from '../../myAccount.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  hide2 = true;
  hide3 = true;
  email: any;
  token: any;

  constructor(private _fb: FormBuilder, private _myAccountService: MyAccountService, private route: ActivatedRoute) { }

  resetPasswordForm = this._fb.group({
    token: ['', Validators.required],
    email: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    )]],
    confirmNewPssword: ['', [Validators.required]]
  },
    {
      validators: MustMatch('newPassword', 'confirmNewPssword')
    }
  );
  public error = (control: string, error: string) => {
    return this.resetPasswordForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.email = params.email
      this.token = params.token
    })
  }

  postResetPassword() {
    this._myAccountService.postResetpassword(this.resetPasswordForm.value).subscribe((result: any) => {
      if (result.status == "success") {
        onSweetAlertSuccess(result).then((isCheckOk) => {
          if (isCheckOk.isConfirmed) {
            commonRouting('login');
          }
        });
      } else if (result.status == "error") {
        onSweetAlertError(result);
      }
    })
  }

  cancelBtn() {
    commonRouting('forgotpassword');
  }

}

export function MustMatch(controlName: any, matchingControlName: any) {
  {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      else if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}
