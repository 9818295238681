import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AppInjector } from "./app.module";

export function onSelectedFile(event: any) {
    let selectedFile = <File>event.target.files[0];
    let selectedFileName = selectedFile.name;
    return selectedFileName;
}

export function onSweetAlertSuccess(result: any) {
    return Swal.fire({
        text: result.message,
        icon: 'success',
        width: 400,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertError(result: any) {
    return Swal.fire({
        text: result.message,
        icon: 'error',
        width: 400,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertError1(err: any) {
    return Swal.fire({
        text: err.error.message,
        icon: 'error',
        width: 400,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertDelete() {
    return Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        width: 400,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
    })
}

export function onSweetAlertDeletedSuccess(result: any) {
    return Swal.fire({
        title: 'Deleted!',
        text: result.message,
        icon: 'success',
        width: 400,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}


export function onSweetAlertDeletedError(result: any) {
    return Swal.fire({
        title: 'Cancelled!',
        text: result.message,
        icon: 'error',
        width: 400,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function commonRouting(routing: any) {
    const router = AppInjector.get(Router);
    router.navigateByUrl(routing);
}

export function commonRoutingWithId(routing: any, id: any) {
    const router = AppInjector.get(Router);
    router.navigateByUrl(routing, id);
}


export function optimiseGetApi(url: any) {
    const _http = AppInjector.get(HttpClient);
    return _http.get(url);
}

export function optimisePostApi(url: any, data: any) {
    const _http = AppInjector.get(HttpClient);
    return _http.post(url, data);
}

export function optimiseDeleteApi(id: any) {
    const _http = AppInjector.get(HttpClient);
    return _http.delete(id);
}

export function optimiseEditApi(getId: any) {
    const _http = AppInjector.get(HttpClient);
    return _http.get(getId);
}

export function optimiseUpdateApi(url: any, data: any) {
    const _http = AppInjector.get(HttpClient);
    return _http.put(url, data);
}