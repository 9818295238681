<h1 class="text-center  my-4 py-4">Recent List</h1>
<mat-form-field class="w-25 mr-3" appearance="outline">
    <mat-label class="text-dark">Search here</mat-label>
    <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
</mat-form-field>

<div class="mat-elevation-z8">
    <mat-table [dataSource]="recentList" matSort id="table">
        <ng-container matColumnDef="emailId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.emailId}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="recentDateTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date and Time </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.recentDateTime | date: 'dd/MMM/yyyy'}} - {{element.recentDateTime | date:'hh:mm a'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="imageName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Download Image </mat-header-cell>
            <mat-cell *matCellDef="let element"> <img src="{{element.imageName}}" height="20px" width="30px"> </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <div class="mat-row" *matNoDataRow>
            <br>
            <p class=" text-center text-muted" colspan="4">No record found.</p>
        </div>
    </mat-table>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
        </mat-paginator>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>