import { Injectable } from '@angular/core';
import { optimisePostApi } from '../optimiseCode';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {  

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  postChangePassword(obj: any) {
    return this._http.post(`${environment.api_url}/api/account/changepassword`, obj, { headers: this.headers });
  }

  postForgotPassword(email: any) {
    return this._http.post(`${environment.api_url}/api/account/forgotpassword?email=${email}`, email);
  }

  postResetpassword(data: any) {
    return this._http.post(`${environment.api_url}/api/account/resetpassword`, data);
  }

}