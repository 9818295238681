import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { CategoryWiseImagesService } from '../service/category-wise-images.service';

@Component({
  selector: 'app-category-wise-image-list',
  templateUrl: './category-wise-image-list.component.html',
  styleUrls: ['./category-wise-image-list.component.css']
})
export class CategoryWiseImageListComponent implements OnInit {
  displayedColumns: any[] = ['imageName', 'image', 'color', 'action'];
  categoryWiseImageList: any = [];
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  subcategoryNameTitle = sessionStorage.getItem('SubCategoryTitle');
  subcategoryId:any = sessionStorage.getItem('SubCategoryId');
  categoryId:any = sessionStorage.getItem('CategoryId');
  FILTER = "";
  load:any;

  constructor(private _route: Router, public _loaderService: LoaderService, private _categoryWiseImageService: CategoryWiseImagesService) { }

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngOnInit(): void {
    this.load = this._loaderService.isLoading;
    if (sessionStorage.getItem('categoryWiseImagePage') != null) {
      this.pageSize = sessionStorage.getItem('categoryWiseImagePage');
      this.getAllCategoryWiseImageList();
    }
    else {
      sessionStorage.setItem('categoryWiseImagePage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('categoryWiseImagePage1');
      this.getAllCategoryWiseImageList();
    }
  }

  getAllCategoryWiseImageList() {
    this._categoryWiseImageService.getCategoryWiseImageList(this.pageIndex, this.pageSize, this.FILTER, this.subcategoryId).subscribe((result: any) => {
      this.load = false;
      this.categoryWiseImageList = result;
      if (result.length > 0) {
        this.totalRecords = this.categoryWiseImageList[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      this.categoryWiseImageList = new MatTableDataSource(this.categoryWiseImageList);
      this.categoryWiseImageList.sort = this.sort;
    });
  }

  clickToAddCategoryWiseImages() {
    commonRouting('home/categoryWiseImagesAdd');
  }

  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._categoryWiseImageService.deleteCategoryWiseImageList(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  dataFilter(event: any) {
    this.categoryWiseImageList.filter = event.target.value.trim().toLowerCase();
    this.FILTER = this.categoryWiseImageList.filter;
    this.getAllCategoryWiseImageList();
  }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('categoryWiseImagePage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('categoryWiseImagePage', this.pageSize);
    this.getAllCategoryWiseImageList();
  }

  backToSubCategory(){
    let selectedId = this.categoryId;
    this._route.navigate(['/home/subcategory/', selectedId]);
  }
}
