import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseGetApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyFeedbackService {

  constructor(private _http:HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  getFeedBackList(PageIndex:any, PageSize:any, Search:any) {
    return this._http.get(`${environment.api_url}/api/feedback/getfeedbacklist?devicetype=website&PageIndex=${PageIndex}&PageSize=${PageSize}&Search=${Search}`, { headers: this.headers });
  }

  deleteFeedBackList(id: any) {
    return this._http.delete(`${environment.api_url}/api/feedback/deletefeedback?Id=${id}`, { headers: this.headers });
  }
}