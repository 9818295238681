import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError1, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyAddUserService } from '../my-add-user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  load: any;
  hide = true;

  constructor(private _fb: FormBuilder, private _myAddUserService: MyAddUserService, private _activatedRoute: ActivatedRoute, private _router: Router, public _loaderService: LoaderService) { }

  userlist = this._fb.group({
    email: ['', [Validators.required]],
    firstname: ['', [Validators.required, Validators.pattern('^[a-zA-Zs]*$')]],
    lastname: ['', [Validators.required, Validators.pattern('^[a-zA-Zs]*$')]],
    // username: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    password: ['', Validators.required]
  });

  public error = (controlName: string, errorName: string) => {
    return this.userlist.controls[controlName].hasError(errorName);
  }

  ngOnInit() {

  }

  onSubmit() {
    this.load = this._loaderService.isLoading;
    if (this.userlist.invalid) {
      this.load = false;
      return;
    }
    else {
      this._myAddUserService.postUserList(this.userlist.value).subscribe((result: any) => {
        this.load = false;
        if (result.status == "Success") {
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              commonRouting('login');
            }
          });
        }
      }, (err:any) => {
        this.load = false;
        if (err.error.status == "Error") {
          onSweetAlertError1(err);
        }
      });
    }
  }

  omit_special_char_name(event: any) {
    var k;
    k = event.charCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123)
  }

}
