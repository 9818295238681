<div class="pt-5">
  <mat-card class="w-50 mx-auto p-5">
    <h3 class="text-primary">Forgot Password?</h3>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="postForgotPassword()">
      <div class="example-container">
        <mat-form-field class="w-75 p-1" appearance="outline">
          <mat-label class="text-dark">Enter your email</mat-label>
          <input matInput placeholder="pat@example.com" formControlName="email" required autocomplete="on">
          <mat-error *ngIf="error('email', 'required')">Please enter email id.</mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary" type="submit" class="my-2 w-25 mr-2">Send</button>
      <button mat-stroked-button color="primary" (click)="cancelBtn()" type="button" class="my-2 w-25">Cancel</button>
    </form>
  </mat-card>
</div>

<div *ngIf="load | async" class="loading-bar">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>