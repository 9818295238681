<div>
    <nav class="navbar navbar-expand-lg navbar-dark" style="margin: -16px -28px;">
        <div class="container-fluid" style="padding: 5px;" id="div">
            <a class="navbar-brand logo" routerLink="dashboard">
               <!-- <span class="span1">SPARKWALL</span> -->
                <img src="/assets/images/SparkWall_icon.jpg" width="30px" style="border-radius: 15px;" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                <ul class="navbar-nav ms-lg-3">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="dashboard" routerLinkActive="active">Home </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="UserList" routerLinkActive="active">User List</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['categories']" routerLinkActive="active">Category List</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['changepassword']" routerLinkActive="active">Change
                            Password</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['feedBack']" routerLinkActive="active">Feedback</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['updateVersion']" routerLinkActive="active">Update
                            Version</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['favourite']" routerLinkActive="active">Favourite & Download</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['recent']" routerLinkActive="active">Recent</a>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" (click)="onLogOut()" routerLinkActive="active">Log
                            Out</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>

<div class="container">
    <router-outlet></router-outlet>
</div>