import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSelectedFile, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { CategoriesService } from '../service/categories.service';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.css']
})
export class AddCategoriesComponent implements OnInit {
  editMode = false;
  selectedFile: any;
  previousImg: any;
  selectedFileName: any;
  selectedFileShow: any;
  click: any;
  isSubmitted = false;
  load: any;

  // 

  constructor(private _fb: FormBuilder, private _categoryService: CategoriesService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService, private _route: Router) { }

  addCategoriesForm: FormGroup = this._fb.group({
    categoryName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    image: [''],
    imageFile: ['', [Validators.required]],
    isActive: [true, [Validators.required]],
  });

  public hasError = (controlName: string, errorName: string) => {
    return this.addCategoriesForm.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.id != undefined) {
      this.load = this._loaderService.isLoading;
      this._categoryService.getByIdCategoryList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
        this.load = false;
        this.editMode = true;
        this.previousImg = result.image;
        this.addCategoriesForm = this._fb.group({
          categoryID: [result.categoryID],
          categoryName: [result.categoryName, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
          image: [result.image],
          imageFile: [result.imageFile],
          isActive: [result.isActive, [Validators.required]],
        });
      });
    }
    else {
      this.load = false;
    }
  }

  onFileSelect(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = this.selectedFile.name;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    this.load = this._loaderService.isLoading;
    if (this.addCategoriesForm.invalid) {
      this.load = false;
      return;
    }
    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
      const formData = new FormData();
      formData.append('categoryName', this.addCategoriesForm.value.categoryName);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);
      formData.append('isActive' ,this.addCategoriesForm.value.isActive);
      this._categoryService.postCategoryList(formData).subscribe((result: any) => {
        this.load = false;
        if (result.status == "success") {
          this.click = !this.click;
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              commonRouting('home/categories');
            }
          });
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })
    }
    else {
      const formData = new FormData();
      formData.append('categoryID', this._activatedRoute.snapshot.params.id);
      formData.append('categoryName', this.addCategoriesForm.value.categoryName);
      formData.append('isActive' ,this.addCategoriesForm.value.isActive);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName) {
        formData.append('image', this.selectedFileName);
      } else {
        formData.append('image', this.previousImg);
      }
      this._categoryService.updateCategoryList(formData).subscribe((result: any) => {
        this.load = false;
        if (result.status == "success") {
          this.click = !this.click;
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              commonRouting('home/categories');
            }
          });
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })
    }
  }

  clicktobackList() {
    commonRouting('home/categories');
  }

  resetForm() {
    this.selectedFileShow = null;
  }
}

