import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyUserService {
  public url = `${environment.api_url}/api/account`;

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });


  getUserList(pageSize: any, pageIndex: any, search: any) {
    return this._http.get(`${this.url + "/getuserlist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}`, { headers: this.headers });
  }

  deleteUserList(id: any) {
    return this._http.delete(`${this.url + "/deleteuser?UserId="}${id}`, { headers: this.headers });
  }

  // postUserList(data: any) {
  //   return this._http.post(this.url + "/register-admin", data);
  // }

  
}
