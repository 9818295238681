<h1 class="text-center  my-4 py-4">Feedback List</h1>
<mat-form-field class="w-25" appearance="outline">
    <mat-label class="text-dark">Search here</mat-label>
    <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
</mat-form-field>
<div class="mat-elevation-z8">
    <mat-table [dataSource]="feedBackList" matSort id="table">
        <ng-container matColumnDef="emailId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.emailId}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rating">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Rating </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.rating}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ratingText">
            <mat-header-cell *matHeaderCellDef mat-sort-header> RatingText </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ratingText}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
            <mat-cell *matCellDef="let element"> <i style="cursor: pointer;" (click)="deleteRow(element.id)">
                    <mat-icon color="warn">delete</mat-icon>
                </i></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <div class="mat-row" *matNoDataRow>
            <br>
            <p class=" text-center text-muted" colspan="4">No record found.</p>
        </div>
    </mat-table>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
    </mat-paginator>
</div>
<!-- loading spinner -->
<div *ngIf="_loaderService.isLoading | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>