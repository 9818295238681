import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSelectedFile, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { CategoryWiseImagesService } from '../service/category-wise-images.service';

@Component({
  selector: 'app-add-category-wise-images',
  templateUrl: './add-category-wise-images.component.html',
  styleUrls: ['./add-category-wise-images.component.css']
})
export class AddCategoryWiseImagesComponent implements OnInit {
  editMode = false;
  selectedFile: any;
  previousImg: any;
  selectedFileName: any;
  selectedFileShow: any;
  click: any;
  isSubmitted = false;
  load: any;

  public subcategoryId: any = sessionStorage.getItem('SubCategoryId');
  public SUBCATEGORYID = this.subcategoryId;

  ColorList = [
    {name:'Red'},
    {name:'White'},
    {name:'Black'},
    {name:'Yellow'},
    {name:'Orange'},
    {name:'Blue'},
    {name:'Violet'},
    {name:'Green'},
    {name:'Brown'},
    {name:'Pink'},
    {name:'Grey'}
  ];
  
  constructor(private _fb: FormBuilder, private _router: Router, private _categoryWiseImageService: CategoryWiseImagesService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  addCategoryWiseImageForm: FormGroup = this._fb.group({
    subCategoryID: [this.SUBCATEGORYID],
    color:['', [Validators.required]],
    imageName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    image: [''],
    imageFile: ['', [Validators.required]],
    isActive: [true, [Validators.required]],
  });

  public hasError = (controlName: string, errorName: string) => {
    return this.addCategoryWiseImageForm.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.id != undefined) {
      this.load = this._loaderService.isLoading;
      this._categoryWiseImageService.getByIdCategoryWiseImageList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
        this.load = false;
        this.editMode = true;
        this.previousImg = result.image;
        this.addCategoryWiseImageForm = this._fb.group({
          subCategoryID: [this.SUBCATEGORYID],
          imageID: [result.imageID],
          color:[result.color, [Validators.required]],
          imageName: [result.imageName, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
          image: [result.image],
          imageFile: [result.imageFile],
          isActive: [result.isActive, [Validators.required]],
        });
      });
    }
    else {
      this.load = false;
    }
  }

  onFileSelect(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = this.selectedFile.name;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    this.load = this._loaderService.isLoading;
    if (this.addCategoryWiseImageForm.invalid) {
      this.load = false;
      return;
    }
    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
      const formData = new FormData();
      formData.append('subCategoryID', this.addCategoryWiseImageForm.value.subCategoryID);
      formData.append('color', this.addCategoryWiseImageForm.value.color);
      formData.append('imageName', this.addCategoryWiseImageForm.value.imageName);
      formData.append('isActive' ,this.addCategoryWiseImageForm.value.isActive);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);
      this._categoryWiseImageService.postCategoryWiseImageList(formData).subscribe((result: any) => {
        this.load = false;
        if (result.status == "success") {
          this.click = !this.click;
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              this._router.navigate(['/home/categoryWiseImages/', this.subcategoryId]);
            }
          });
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })
    }

    else {
      const formData = new FormData();
      formData.append('imageID', this._activatedRoute.snapshot.params.id);
      formData.append('subCategoryID', this.addCategoryWiseImageForm.value.subCategoryID);
      formData.append('color', this.addCategoryWiseImageForm.value.color);
      formData.append('imageName', this.addCategoryWiseImageForm.value.imageName);
      formData.append('isActive' ,this.addCategoryWiseImageForm.value.isActive);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName) {
        formData.append('image', this.selectedFileName);
      } else {
        formData.append('image', this.previousImg);
      }
      this._categoryWiseImageService.updateCategoryWiseImageList(formData).subscribe((result: any) => {
        this.load = false;
        if (result.status == "success") {
          this.click = !this.click;
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              this._router.navigate(['/home/categoryWiseImages/', this.subcategoryId]);
            }
          });
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })
    }
  }

  clicktobackList() {
    this._router.navigate(['/home/categoryWiseImages/', this.subcategoryId]);
  }

  resetForm() {
    this.selectedFileShow = null;
  }
}