<h1 id="h1" class="my-4 py-4">User List</h1>
<mat-form-field class="w-25" appearance="outline">
  <mat-label class="text-dark">Search here</mat-label>
  <input matInput (keyup)="dataFilter($event)" placeholder="Search...">
</mat-form-field>
<button (click)="clickToAddUser()" mat-raised-button color="primary" id="course"><svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24" width="24" height="24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
  </svg> Add User</button>
<div class="mat-elevation-z8 ">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="firstname">
      <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.firstname}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastname">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.lastname}} </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header> User Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
      <mat-cell *matCellDef="let element" class="me-3"> {{element.email}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="phoneNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.phoneNumber}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element"> <a routerLink="/home/updateUser/{{element.id}}"><mat-icon color="primary">edit</mat-icon></a> <a  class="deletebtn" (click)="deleteRow(element.id)">
        <mat-icon color="warn">delete</mat-icon>
      </a> </mat-cell>
  </ng-container>
    <!-- <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/home/updateUser/{{element.id}}">
            <mat-icon color="primary">edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="deleteRow(element.id)">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container> -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <div class="mat-row" *matNoDataRow>
      <br>
      <p class=" text-center text-muted" colspan="4">No record found.</p>
    </div>
  </mat-table>
  <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex-1"
    aria-label="Select page">
  </mat-paginator>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>