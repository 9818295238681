import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyUpdateVersionService } from '../myUpdateVersion.service';

@Component({
  selector: 'app-updateVersion',
  templateUrl: './updateVersion.component.html',
  styleUrls: ['./updateVersion.component.css']
})
export class UpdateVersionComponent implements OnInit {
  id: any;
  appName: any;
  version: any;
  status: any;
  status1: any;
  click: any;
  load:any;

  constructor(private _fb: FormBuilder, private _activatedRoute: ActivatedRoute, private _updateVersion: MyUpdateVersionService, private _router: Router, public _loaderService: LoaderService) { }

  updateVersion = this._fb.group({
    appName: [''],
    version: [''],
    status: []
  });

  ngOnInit() {
    this.id = localStorage.getItem('versionId');
    this.appName = localStorage.getItem('versionAPPName');
    this.version = localStorage.getItem('version');
    this.status = localStorage.getItem('versionStatus');
    this.status1 = JSON.parse(this.status);
    this.updateVersion = this._fb.group({
      id: [this.id],
      appName: [this.appName],
      version: [this.version],
      status: [this.status1]
    });
  }

  onSubmit() {
    this.load = this._loaderService.isLoading;
    this.updateVersion.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
    this._updateVersion.updateUpdateVersionList(this.updateVersion.value).subscribe((result: any) => {
      this.load=false;
      if (result.status == "success") {
        this.click = !this.click;
        onSweetAlertSuccess(result).then((isCheckOk) => {
          if (isCheckOk.isConfirmed) {
            this._router.navigate(['/home/updateVersion']);
          }
        });
      }
    });
  }

  clicktobackList() {
    this._router.navigate(['/home/updateVersion']);
  }

}