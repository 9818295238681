import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { MyUpdateVersionService } from '../myUpdateVersion.service';

@Component({
  selector: 'app-updateVersionList',
  templateUrl: './updateVersionList.component.html',
  styleUrls: ['./updateVersionList.component.css']
})
export class UpdateVersionListComponent implements OnInit {

  displayedColumns: any[] = ['appName', 'version', 'status', 'action'];
  updateVersionList1: any;

  constructor(private _updateVersion: MyUpdateVersionService, public _loaderService: LoaderService) { }

  ngOnInit() {
    this._updateVersion.getUpdateVersionList().subscribe((result: any) => {
      this.updateVersionList1 = result;
      localStorage.setItem('versionId', result[0].id);
      localStorage.setItem('versionAPPName', result[0].appName);
      localStorage.setItem('version', result[0].version);
      localStorage.setItem('versionStatus', result[0].status);
    })
  }
}
