import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyRecentService {
  private ROOT_URL = `${environment.api_url}`;

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  getRecentList(PageIndex:any, PageSize:any, Search:any) {
    if(Search){
      return this._http.get(`${this.ROOT_URL}/api/Recent/getRecentlist?devicetype=website&PageIndex=${PageIndex}&PageSize=${PageSize}&Search=${Search}`, { headers: this.headers });
    }
    else{
      return this._http.get(`${this.ROOT_URL}/api/Recent/getRecentlist?devicetype=website&PageIndex=${PageIndex}&PageSize=${PageSize}`, { headers: this.headers });
    }
  }

}
