<div id="div" class="mt-4 pt-4">
    <form id="form" [formGroup]="userlist">
      <h1 id="h1" class="mt-2">Update User</h1>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Email ID</mat-label>
        <input matInput formControlName="email" pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required
          autocomplete="on">
        <mat-error *ngIf="error('email','required')">Email is required.</mat-error>
        <mat-error *ngIf="error('email','pattern')">Please enter valid email id.</mat-error>
      </mat-form-field><br><br>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">First Name</mat-label>
        <input matInput formControlName="firstname" (keypress)="omit_special_char_name($event)" required autocomplete="on">
        <mat-error *ngIf="error('firstname','required')">First name is required.</mat-error>
        <mat-error *ngIf="error('firstname', 'pattern')">First name are not allowed
          space, special character and
          number.</mat-error>
      </mat-form-field><br><br>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Last Name</mat-label>
        <input matInput formControlName="lastname" (keypress)="omit_special_char_name($event)" required autocomplete="on">
        <mat-error *ngIf="error('lastname','required')">Last name is required.</mat-error>
        <mat-error *ngIf="error('lastname', 'pattern')">Last name are not allowed
          space, special character and
          number.</mat-error>
      </mat-form-field><br><br>
      <!-- <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Password</mat-label>
        <input matInput formControlName="password" required autocomplete="on">
        <mat-error *ngIf="error('password','required')">Last Name is required</mat-error>
      </mat-form-field><br><br> -->
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Phone Number</mat-label>
        <input matInput formControlName="phoneNumber" maxlength="10" required autocomplete="on">
        <mat-error *ngIf="error('phoneNumber','required')">Phone number is required.</mat-error>
        <mat-error *ngIf="error('phoneNumber','pattern')">Please enter 10 digits only.</mat-error>
      </mat-form-field><br><br>
      <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Submit</button>
      <button mat-raised-button color="warn" type="reset" class="mx-1">Clear</button>
      <button mat-stroked-button color="primary" type="button" [routerLink]="['/home/UserList']">Cancel</button>
    </form>
  </div>
  <!-- loading spinner -->
  <div *ngIf="_loaderService.isLoading | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>