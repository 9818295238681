<div id="div" class="mt-4 pt-4">
    <form id="form" [formGroup]="userlist">
      <h1 id="h1" class="mt-2">Add User</h1>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">First Name</mat-label>
        <input type="text" matInput formControlName="firstname" (keypress)="omit_special_char_name($event)" required>
        <mat-error *ngIf="error('firstname','required')">Please enter first name.</mat-error>
        <mat-error *ngIf="error('firstname', 'pattern')">First name are not allowed
          space, special character and
          number.</mat-error>
      </mat-form-field><br><br>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Last Name</mat-label>
        <input type="text" matInput formControlName="lastname" (keypress)="omit_special_char_name($event)" required>
        <mat-error *ngIf="error('lastname','required')">Please enter last name.</mat-error>
        <mat-error *ngIf="error('lastname', 'pattern')">Last name are not allowed
          space, special character and
          number.</mat-error>
      </mat-form-field><br><br>
      <!-- <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Last Name</mat-label>
        <input type="text" matInput formControlName="username" required>
        <mat-error *ngIf="error('username','required')">Please enter last name</mat-error>
      </mat-form-field><br><br> -->
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Email ID</mat-label>
        <input type="email" matInput formControlName="email" pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required
          autocomplete="on">
        <mat-error *ngIf="error('email','required')">Please enter email id.</mat-error>
        <mat-error *ngIf="error('email','pattern')">Please enter valid email id.</mat-error>
      </mat-form-field><br><br>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Phone Number</mat-label>
        <input type="text" matInput formControlName="phoneNumber" maxlength="10" required autocomplete="on">
        <mat-error *ngIf="error('phoneNumber','required')">Please enter phone number.</mat-error>
        <mat-error *ngIf="error('phoneNumber','pattern')">Please enter 10 digits only.</mat-error>
      </mat-form-field><br><br>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label class="text-dark">Password</mat-label>
        <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
          pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" required autocomplete="on">
        <button mat-icon-button color="primary" matSuffix (click)="hide = !hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="error('password','required')">Please enter password.</mat-error>
        <mat-error *ngIf="error('password','pattern')">Password should have minimum 8 characters and atleast 1 uppercase, 1
          lowercase, 1 special character, 1 number.</mat-error>
      </mat-form-field><br><br>
      <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Submit</button>
      <button mat-raised-button color="warn" type="reset" class="mx-1">Clear</button>
      <button mat-stroked-button color="primary" type="button" [routerLink]="['/home/UserList']">Cancel</button>
    </form>
  </div>
  <!-- loading spinner -->
  <div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>