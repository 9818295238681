<h1 id="h1" class="text-center ">Welcome to WallPaper App</h1>
<div id="div" class="" style="padding-top:10%;">
  <mat-card class="card mx-auto">
    <mat-card-title>
      <h1 id="h" class="text-center">SIGN IN</h1>
    </mat-card-title>
    <mat-card-content id="content">
      <form [formGroup]="login" id="form" class="my-5 " novalidate>
        <mat-form-field class="input-field" class="w-100" appearance="outline">
          <mat-label class="text-dark">Email Id</mat-label>
          <input type="email" matInput formControlName="email" [(ngModel)]="EMAIL" required autocomplete="on">
          <mat-error *ngIf="error('email', 'required')">Email is required.</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class="input-field" class="w-100" appearance="outline">
          <mat-label class="text-dark">Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" [(ngModel)]="PASSWORD" required autocomplete="on">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide" type="button">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="error('password', 'required')">Password is required.</mat-error>
        </mat-form-field>
        <br><br>
        <div class="w-100">
          <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" class="w-100">LOG IN</button><br>
          <div class="my-2"></div>
          <!-- <span style="font-size: large; margin-right: 30px; margin-left: 30px;">Don't have an account?</span><button
            mat-stroked-button color="primary" type="button" (click)="signUp()" class="w-25">Sign Up</button><br> -->
          <div class="my-2"></div>
          <mat-checkbox [checked]="rememberMe" color="primary" (change)="remember($event)">Remember me</mat-checkbox>
          <a (click)="forgotPasswordNavigate()" class="float-end my-2  materialColor ">Forgot Password?</a>
        </div>
      </form>
    </mat-card-content>
    <div class="alert alert-danger" role="alert" *ngIf="errorMsgHide">
      <span *ngIf="errorMsgHide">{{msg}}</span>
    </div>
  </mat-card>
</div>

<div *ngIf="load | async" class="loading-bar">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>