import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { CategoriesService } from '../service/categories.service';

@Component({
  selector: 'app-all-categories-list',
  templateUrl: './all-categories-list.component.html',
  styleUrls: ['./all-categories-list.component.css']
})
export class AllCategoriesListComponent implements OnInit {

  displayedColumns: any[] = ['categoryName', 'image', 'category', 'action'];
  categoryList: any = [];
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  FILTER = "";
  load: any;
  Category_Name: any;

  constructor(private _route: Router, public _loaderService: LoaderService, private _categoryService: CategoriesService) { }
  GETTITLE = sessionStorage.getItem('CategoryTitle');

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;


  ngOnInit(): void {
    this.load = this._loaderService.isLoading;
    if (sessionStorage.getItem('categoryPage') != null) {
      this.pageSize = sessionStorage.getItem('categoryPage');
      this.getAllCategoryList();
    }
    else {
      sessionStorage.setItem('categoryPage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('categoryPage1');
      this.getAllCategoryList();
    }

  }

  getAllCategoryList() {
    this._categoryService.getCategoryList(this.pageIndex, this.pageSize, this.FILTER).subscribe((result: any) => {
      this.load = false;
      this.categoryList = result;
      this.categoryList.filter((x: any) => {
        if (x.categoryName == this.GETTITLE) {
          this.Category_Name = x.categoryName
        }
      });
      
      if (result.length > 0) {
        this.totalRecords = this.categoryList[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      this.categoryList = new MatTableDataSource(this.categoryList);
      this.categoryList.sort = this.sort;
    });
  }

  clickToAddCategory() {
    commonRouting('home/categoriesAdd');
  }

  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._categoryService.deleteCategoryList(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  dataFilter(event: any) {
    this.categoryList.filter = event.target.value.trim().toLowerCase();
    this.FILTER = this.categoryList.filter;
    this.getAllCategoryList();
  }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('categoryPage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('categoryPage', this.pageSize);
    this.getAllCategoryList();
  }

  clickToSubCategoryList(obj: any) {
    sessionStorage.setItem('CategoryTitle', obj.categoryName);
    sessionStorage.setItem('CategoryId', obj.categoryID);
    this._route.navigate(['/home/subcategory', obj.categoryID]);
  }
}