import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimiseGetApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyUpdateVersionService {

  constructor() { }

  getUpdateVersionList() {
    return optimiseGetApi(`${environment.api_url}/api/appversion/getupdateversionbyid?id=1`);
  }

  updateUpdateVersionList(data: any) {
    return optimiseUpdateApi(`${environment.api_url}/api/appversion/updateversion`, data);
  }
}
