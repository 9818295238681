import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  private ROOT_URL = `${environment.api_url}`;

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  getSubCategoryList(PageIndex: any, PageSize: any, Search: any, id:any) {
    return this._http.get(`${this.ROOT_URL}/api/Subcategory/getSubCategorylistByCategoryId?CategoryId=${id}&PageIndex=${PageIndex}&PageSize=${PageSize}&Search=${Search}&devicetype=website`, { headers: this.headers });
  }

  postSubCategoryList(data: any) {
    return this._http.post(`${this.ROOT_URL}/api/Subcategory/addSubCategory`, data, { headers: this.headers });
  }

  getByIdSubCategoryList(id: any) {
    return this._http.get(`${this.ROOT_URL}/api/Subcategory/getSubCategorybyid?id=${id}&devicetype=website`, { headers: this.headers });
  }

  updateSubCategoryList(data: any) {
    return this._http.post(`${this.ROOT_URL}/api/Subcategory/addSubCategory`, data, { headers: this.headers });
  }

  deleteSubCategoryList(id: any) {
    return this._http.delete(`${this.ROOT_URL}/api/Subcategory/deleteSubCategory?id=${id}`, { headers: this.headers });
  }
}
